<template>
    <v-main fluid>
        <v-container class="p-0 px-2">
            <v-row class="d-flex justify-content-center">
                <v-col cols="12" sm="10" md="7" lg="5">
                    <div class="bg-white border p-5">
                        <h1 class="display-3">{{$t("Forgot")}}</h1>
                        <hr />
                        <v-alert v-show="phase == 'request'" text color="primary" icon="mdi-information-outline"><span class="body-2" v-html="$t('FORGOT_PAGE_CAPTION_MESSAGE')"></span></v-alert>
                        <div v-show="phase == 'verification'">
                            <v-alert text color="primary" icon="mdi-information-outline">{{$t('SMSSentToYourPhoneContactDepartmentIfYouThinkItsWrong', { 0: phonecode, 1: maskedphonenumber })}}</v-alert>
                            <v-alert v-show="timerremaining == 0" text color="red" icon="mdi-clock-outline">{{$t("CodeExpiredSendNewCodeRequest")}}</v-alert>
                            <v-alert v-show="timerremaining > 0" text color="primary" icon="mdi-clock-outline">{{$t("CodeIsValidForXSecond")}}: {{timerremaining}} {{$t("Second")}}</v-alert>
                        </div>

                        {{$t("EmailAdress")}}
                        <v-text-field id="email"
                                      ref="email"
                                      v-model="model.email"
                                      v-bind:error="errors.email"
                                      v-bind:disabled="disable.email"
                                      dense
                                      outlined
                                      hide-details
                                      color="primary"
                                      append-icon="email"
                                      type="email"
                                      v-bind:placeholder="$t('EmailAdress')"
                                      v-on:keyup.enter="OnDefaultEnter"
                                      class="mb-3"></v-text-field>

                        <div v-if="phase == 'request'">
                            <v-row class="my-3 d-flex justify-content-center align-items-center">
                                <v-col cols="auto" class="mr-0 pr-0">
                                    <v-skeleton-loader v-if="captchaUrl.length == 0"
                                                       max-width="240"
                                                       max-height="70"
                                                       min-width="240"
                                                       min-height="70"
                                                       type="image"></v-skeleton-loader>
                                    <img v-else
                                         alt="Captcha"
                                         v-bind:src="captchaUrl"
                                         width="240"
                                         height="70"
                                         v-bind:style="{ 'filter' : 'blur(' + (captchaBlur ? '5px' : '0px') + ')'}" />
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn depressed icon v-on:click="ReloadCaptcha()" color="primary">
                                        <v-icon>{{ captchaBlur ? 'mdi-eye' : 'cached'}}</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            {{$t("SecurityCode")}}
                            <v-text-field v-model="model.captcha"
                                          v-bind:error="errors.captcha"
                                          v-bind:disabled="disable.captcha"
                                          dense
                                          outlined
                                          hide-details
                                          color="primary"
                                          append-icon="mdi-shield-check"
                                          v-bind:placeholder="$t('SecurityCode')"
                                          v-on:keyup.enter="OnDefaultEnter"
                                          class="mb-5"></v-text-field>
                        </div>
                        <div v-else-if="phase == 'verification'">
                            {{$t("OTPCode")}}
                            <v-text-field v-model="model.otp"
                                          v-bind:error="errors.otp"
                                          v-bind:disabled="timerremaining == 0"
                                          dense
                                          outlined
                                          hide-details
                                          color="primary"
                                          append-icon="dialpad"
                                          v-bind:placeholder="$t('OTPCode')"
                                          v-on:keyup.enter="OnDefaultEnter"
                                          class="mb-5"></v-text-field>

                        </div>
                        <div v-else>
                            {{$t("Password")}}
                            <v-text-field id="password"
                                          ref="password"
                                          v-model="modelpassword"
                                          v-bind:error="errors.password"
                                          v-bind:disabled="disable.password"
                                          dense
                                          outlined
                                          hide-details
                                          color="primary"
                                          v-bind:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                          v-on:click:append="showpassword = !showpassword"
                                          v-bind:type="showpassword ? 'text' : 'password'"
                                          v-bind:placeholder="$t('Password')"
                                          v-on:focus="SetSnackbar('password', 1)"
                                          v-on:focusout="SetSnackbar('password', 0)"
                                          v-on:keyup.enter="OnDefaultEnter"
                                          class="mb-3"></v-text-field>
                            <v-list dense v-show="snackbar.password">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title style="text-transform: capitalize" v-for="(item, i) in PassRequirementsMessages.password" v-bind:key="i" v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                            <v-icon left v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                                {{item.satisfy ? 'mdi-check' : 'mdi-close'}}
                                            </v-icon>
                                            {{$t(item.text)}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>

                            {{$t("PasswordAgain")}}
                            <v-text-field id="confirmpassword"
                                          ref="confirmpassword"
                                          v-model="modelconfirmpassword"
                                          v-bind:error="errors.confirmpassword"
                                          v-bind:disabled="disable.confirmpassword"
                                          dense
                                          outlined
                                          hide-details
                                          color="primary"
                                          v-bind:append-icon="showconfirmpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                          v-on:click:append="showconfirmpassword = !showconfirmpassword"
                                          v-bind:type="showconfirmpassword ? 'text' : 'password'"
                                          v-bind:placeholder="$t('PasswordAgain')"
                                          v-on:focus="SetSnackbar('confirmpassword', 1)"
                                          v-on:focusout="SetSnackbar('confirmpassword', 0)"
                                          v-on:keyup.enter="OnDefaultEnter"
                                          class="mb-3"></v-text-field>
                            <div class="pt-1" v-show="snackbar.confirmpassword">
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title style="text-transform: capitalize" v-for="(item, i) in PassRequirementsMessages.confirmpassword" v-bind:key="i" v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                                <v-icon left v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                                    {{item.satisfy ? 'mdi-check' : 'mdi-close'}}
                                                </v-icon>
                                                {{$t(item.text)}}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </div>

                        <v-btn v-if="phase == 'request'" v-bind:disabled="processing" depressed block tile color="primary white--text" v-on:click="Forgot_Request()">{{$t('Forgot')}}</v-btn>
                        <div v-else-if="phase == 'verification'">
                            <v-btn v-if="timerremaining == 0" v-bind:disabled="processing" depressed block tile color="primary white--text" v-on:click="Forgot_Send_OTP()">{{$t('Resend')}}</v-btn>
                            <v-btn v-else v-bind:disabled="processing" depressed block tile color="primary white--text" v-on:click="Forgot_Verification()">{{$t('Verify')}}</v-btn>
                        </div>
                        <v-btn v-else v-bind:disabled="processing" depressed block tile color="primary white--text" v-on:click="Forgot_Finalize()">{{$t('SetPassword')}}</v-btn>

                        <hr />

                        <p><router-link to="/Account/Login">{{$t("Login")}}</router-link></p>
                        <p><router-link to="/Account/Register">{{$t("Register")}}</router-link></p>
                        <p><router-link to="/Account/Application">{{$t("Applicationform")}}</router-link></p>
                    </div>
                    <v-progress-linear v-if="processing" color="primary" indeterminate></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="showErrorPopup" width="390">
            <v-card class="mx-auto pt-7 pb-2">
                <alertIcon icon="warning"></alertIcon>
                <v-card-title>
                    <p class="headline">{{$t("Warning")}}</p>
                </v-card-title>
                <v-card-text>
                    <p v-for="(message, i) in errorMessages" v-bind:key="i" class="mb-0">{{message}}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="warning" block v-on:click="ClearErrors(true)">
                        {{$t("Ok")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="processing" persistent>
            <v-card color="transparent" dark class="py-5">
                <v-card-text class="text-center py-5">
                    <v-progress-circular indeterminate
                                         :size="50"
                                         color="white"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>

<script>
    import axios from 'axios';

    export default {
        name: "Forgot",
        data: function ()
        {
            return {
                accesskey: '',
                phase: 'request',
                phaseHash: 'request',

                processing: false,

                eventid: '',
                phonecode: '',
                maskedphonenumber: '',

                modelpassword: '',
                modelconfirmpassword: '',

                model: {
                    email: '',
                    password: '',
                    otp: '',
                    captcha: ''
                },

                errors: {
                    email: false,
                    password: false,
                    otp: false,
                    captcha: false
                },

                disable: {
                    email: false,
                    password: false,
                    confirmpassword: false,
                    captcha: false,
                    otp: false
                },

                errorMessages: [],

                showpassword: false,
                showconfirmpassword: false,

                snackbar: {
                    password: false,
                    confirmpassword: false
                },

                PassRequirementsMessages:
                {
                    password: [
                        { text: "MustContainLeast6Characters", satisfy: false, regx: /.{6,}/ },
                        { text: "MustContainLeastOneSpecialCharacter", satisfy: false, regx: /[!@#\$%\^\&*\)\(+=._-]/ },
                        { text: "MustContainLeastOneLowercaseLetter", satisfy: false, regx: /[a-z]/ },
                        { text: "MustContainLeastOneUppercaseLetter", satisfy: false, regx: /[A-Z]/ },
                        { text: "MustContainLeastOneNumber", satisfy: false, regx: /\d/ }
                    ],
                    confirmpassword: [
                        { text: "MustContainLeast6Characters", satisfy: false, regx: /.{6,}/ },
                        { text: "MustContainLeastOneSpecialCharacter", satisfy: false, regx: /[!@#\$%\^\&*\)\(+=._-]/ },
                        { text: "MustContainLeastOneLowercaseLetter", satisfy: false, regx: /[a-z]/ },
                        { text: "MustContainLeastOneUppercaseLetter", satisfy: false, regx: /[A-Z]/ },
                        { text: "MustContainLeastOneNumber", satisfy: false, regx: /\d/ }
                    ]
                },

                pageInputs: {
                    'Forgot_Request': ['email', 'captcha'],
                    'Forgot_Verification': ['email', 'otp'],
                    'Forgot_Finalize': ['email', 'password', 'confirmpassword'],
                },

                timeoutid: '',
                timerenable: false,
                timerremaining: 180,
                timerbase: 180,

                captchaBlur: true,
                captchaUrl: '',
            };
        },
        computed: {
            showErrorPopup: function ()
            {
                return this.errorMessages.length > 0;
            },
            LoginRequestButtonDisableState: function ()
            {
                return this.processing;
            }
        },
        methods: {
            OnDefaultEnter: function ()
            {
                if (this.phase == 'request')
                {
                    this.Forgot_Request();
                }
                else if (this.phase == 'verification')
                {
                    if (this.timerremaining == 0)
                    {
                        this.Forgot_Send_OTP();
                    }
                    else
                    {
                        this.Forgot_Verification();
                    }
                }
                else
                {
                    this.Forgot_Finalize();
                }
            },

            SetSnackbar(input, value)
            {
                this.snackbar[input] = value == 1;
            },

            Forgot_Request: function ()
            {
                const self = this;

                if (self.ValidateBeforeAction('Forgot_Request'))
                {
                    self.processing = true;

                    const payload = {
                        phase: self.phaseHash,
                        captcha: self.model.captcha,
                        inputs: {
                            email: self.model.email,
                            accesskey: self.accesskey
                        }
                    };

                    self.$store
                        .dispatch("AccountActionV2", { method: "Forgot", payload: payload })
                        .then(response =>
                        {
                            self.disable.email = true;
                            self.phaseHash = response.data.targetPhaseHash;
                            self.accesskey = response.data.accessKey;
                            self.Forgot_Send_OTP();
                        })
                        .catch(errorResponse =>
                        {
                            self.errorMessages = errorResponse.data.errors.map(msg => self.$t(msg));

                            if (errorResponse.data.backToFirstPhase)
                            {
                                self.ReloadCaptcha();
                                self.ClearModel();
                            }

                            self.processing = false;
                        });
                }
            },

            Forgot_Send_OTP: function ()
            {
                const self = this;

                self.processing = true;

                const payload = {
                    phase: self.phaseHash,
                    captcha: self.model.captcha,
                    inputs: {
                        email: self.model.email,
                        accesskey: self.accesskey
                    }
                };

                self.$store
                    .dispatch("AccountActionV2", { method: "Forgot", payload: payload })
                    .then(response =>
                    {
                        self.phase = response.data.targetPhase;
                        self.phaseHash = response.data.targetPhaseHash;
                        self.accesskey = response.data.accessKey;

                        self.model.otp = response.data.data.otp || '';
                        self.eventid = response.data.data.token;
                        self.phonecode = response.data.data.phonecode;
                        self.maskedphonenumber = response.data.data.maskedphonenumber;

                        self.timerremaining = self.timerbase;
                        self.timerenable = true;

                        self.processing = false;
                    })
                    .catch(errorResponse =>
                    {
                        self.errorMessages = errorResponse.data.errors.map(msg => self.$t(msg));

                        if (errorResponse.data.backToFirstPhase)
                        {
                            self.timerenable = false;
                            self.ReloadCaptcha();
                            self.ClearModel();
                        }

                        self.processing = false;
                    });
            },

            Forgot_Verification: function ()
            {
                if (this.ValidateBeforeAction('Forgot_Verification'))
                {
                    const self = this;

                    self.processing = true;

                    const payload = {
                        phase: self.phaseHash,
                        captcha: self.model.captcha,
                        inputs: {
                            email: self.model.email,
                            accesskey: self.accesskey,
                            token: self.eventid,
                            otp: self.model.otp
                        }
                    };

                    self.$store
                        .dispatch("AccountActionV2", { method: "Forgot", payload: payload })
                        .then(response =>
                        {
                            self.phase = response.data.targetPhase;
                            self.phaseHash = response.data.targetPhaseHash;
                            self.accesskey = response.data.accessKey;

                            self.model.password = '';
                            self.model.confirmpassword = '';

                            self.processing = false;
                        })
                        .catch(errorResponse =>
                        {
                            self.errorMessages = errorResponse.data.errors.map(msg => self.$t(msg));

                            if (errorResponse.data.backToFirstPhase)
                            {
                                self.timerenable = false;
                                self.ReloadCaptcha();
                                self.ClearModel();
                            }

                            self.processing = false;
                        });
                }
            },

            Forgot_Finalize: function ()
            {
                if (this.ValidateBeforeAction('Forgot_Finalize'))
                {
                    const self = this;

                    self.processing = true;

                    const payload = {
                        phase: self.phaseHash,
                        captcha: self.model.captcha,
                        inputs: {
                            email: self.model.email,
                            accesskey: self.accesskey,
                            password: this.model.password,
                            confirmpassword: this.model.confirmpassword,
                        }
                    };

                    self.$store
                        .dispatch("AccountActionV2", { method: "Forgot", payload: payload })
                        .then(response =>
                        {
                            self.processing = false;

                            var messages = {
                                messageBody: self.$t('SUC_FORGOT_FINALIZE_GENERIC_MESSAGE'),
                                infoBody: self.$t('SUCCESS'),
                                messageType: 'B',
                                OnCloseDialog: function ()
                                {
                                    self.$router.push("/Account/Login");
                                }
                            };

                            self.ReloadCaptcha();
                            self.ClearModel();

                            self.$root.$emit('setUpMessage', messages);
                        })
                        .catch(errorResponse =>
                        {
                            self.errorMessages = errorResponse.data.errors.map(msg => self.$t(msg));

                            if (errorResponse.data.backToFirstPhase)
                            {
                                self.timerenable = false;
                                self.ReloadCaptcha();
                                self.ClearModel();
                            }

                            self.processing = false;
                        });
                }
            },

            ValidateBeforeAction: function (action)
            {
                const self = this;

                self.ClearErrors();

                const inputs = this.pageInputs[action]


                for (var ctrl of this.pageInputs[action])
                {
                    var data = this.model[ctrl];

                    if (ctrl == 'email')
                    {
                        this.errors[ctrl] = (data == '');

                        if (!this.errors[ctrl])
                        {
                            var isEmailValidated = this.ValidateEmail(this.model.email);

                            this.errors[ctrl] = (!isEmailValidated);

                            if (!isEmailValidated)
                            {
                                this.errorMessages.push('WarnEmailMustBeInRightFormat');
                            }
                        }
                    }
                    else
                    {
                        this.errors[ctrl] = (data == '');
                    }
                }

                this.PrepareErrorMessage(this.pageInputs[action]);

                return !this.showErrorPopup;
            },

            PrepareErrorMessage: function (pageInputs)
            {
                var requiredErr = false;

                for (var err in this.errors)
                {
                    if (pageInputs.indexOf(err) > -1)
                    {
                        if (this.errors[err] == true)
                        {
                            if (this.model[err] == '') requiredErr = true;
                        }
                    }
                }

                if (requiredErr)
                {
                    this.errorMessages.push(this.$t('WarnYouMustFillAllRequiredFields'));
                }
            },

            ValidateEmail: function (value)
            {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },

            ClearErrors: function (keepInputsCss)
            {
                this.errorMessages = [];

                if (keepInputsCss)
                {
                    return;
                }

                for (var reset in this.errors)
                    this.errors[reset] = false;
            },

            ReloadCaptcha: function (mounted)
            {
                const self = this;

                self.model.captcha = '';
                self.captchaUrl = '';

                const rnd = Math.floor(new Date().getTime() / 1000).toString();

                axios
                    .get(`/api/Account/Captcha?rnd=${rnd}`, {
                        responseType: 'arraybuffer',
                    })
                    .then((response) =>
                    {
                        const blob = new Blob([response.data], { type: response.headers['content-type'] });
                        const imageUrl = URL.createObjectURL(blob);
                        self.captchaUrl = imageUrl;

                        if (!mounted)
                        {
                            self.captchaBlur = false;
                        }
                    })
                    .catch((error) =>
                    {
                        self.errorMessages.push('TOO_MANY_REQUEST_TRY_AGAIN_LATER');
                    });
            },

            ClearModel()
            {
                this.model.email = '';
                this.model.password = '';
                this.model.confirmpassword = '';
                this.model.captcha = '';
                this.modelpassword = '';
                this.modelconfirmpassword = '';
                this.phase = 'request';
                this.showpassword = false;
                this.showconfirmpassword = false;
                this.captchaBlur = true;

                for (var ctrl in this.disable)
                    this.disable[ctrl] = false;
            },
        },
        watch: {
            modelpassword: function (newVal, oldVal)
            {
                this.model.password = newVal;

                for (let requirement of this.PassRequirementsMessages.password)
                {
                    requirement.satisfy = requirement.regx.test(newVal);
                }
            },
            modelconfirmpassword: function (newVal, oldVal)
            {
                this.model.confirmpassword = newVal;

                for (let requirement of this.PassRequirementsMessages.confirmpassword)
                {
                    requirement.satisfy = requirement.regx.test(newVal);
                }
            },
            timerenable:
            {
                handler: function (val, oldVal)
                {
                    if (val)
                    {
                        this.timeoutid = setTimeout(() =>
                        {
                            this.timerremaining--;
                        }, 1000);
                    }
                },
                deep: true
            },
            timerremaining: {
                handler(value)
                {
                    if (value > 0 && this.timerenable)
                    {
                        clearTimeout(this.timeoutid);

                        this.timeoutid = setTimeout(() =>
                        {
                            this.timerremaining--;
                        }, 1000);
                    }

                    if (value == 0)
                    {
                        this.model.verificationcode = '';
                    }
                },
                deep: true
            }
        },
        mounted: function ()
        {
            this.ClearModel();
            this.ReloadCaptcha(true);
            window.scrollTo(0, 0);
        }
    }
</script>